import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"

const LandingPage = () => (
  <Layout>
    <SEO title="Segeln in der Karibik" />
    <Helmet>
      <body className="leading-normal tracking-normal text-white gradient" />
    </Helmet>
    <div class="pt-24">
      <div class="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
            
        {/* Left Col */}
        <div class="flex flex-col w-full md:w-2/5 justify-center items-start text-center md:text-left">
          <p class="uppercase tracking-loose w-full">What business are you?</p>
          <h1 class="my-4 text-5xl font-bold leading-tight">Main Hero Message to sell yourself!</h1>
          <p class="leading-normal text-2xl mb-8">Sub-hero message, not too long and not too short. Make it just right!</p>
          <button class="mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg">Subscribe</button>
        </div>
      
        {/* Right Col */}
        <div class="w-full md:w-3/5 py-6 text-center">
            <img class="w-full md:w-4/5 z-50" src="hero.png" alt="logo" />
        </div>

      </div>
    </div>
  </Layout>
)

export default LandingPage
